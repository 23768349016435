.area-container {
  position: relative;
}

.area-plot {
  position: relative;
  height: 300px;
  background-color: #f1f1f1;
}

.area-plot.collapsed {
  height: 100px;
}

.collapse-button {
  position: relative;
  top: 10px;
  left: 10px;
  float: left;
  width: 24px;
  height: 24px;
  text-align: center;
  font-family: monospace;
  font-size: 19px;
  line-height: 22px;
  cursor: pointer;
  z-index: 50;
  background-color: #357edd;
  color: white;
}

.collapse-button.collapsed {
  line-height: 24px;
}

.menu-toggle {
  display: inline-block;
  float: right;
  font-size: 1.2em;
  cursor: pointer;
  z-index: 11;
  position: relative;
  top: 10px;
  right: 10px;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  color: #999;
}