.added {
    background-color: #0aa10a;
    color: #ffffff
  }

.removed {
    background-color: #a10a0ab6;   
    color: #ffffff
}

.zone-error-div {
    height: 100%;
    overflow-y: auto;
    overflow-x: auto;
}