@import "tachyons";
@import "react-select";
@import "rc-time-picker/assets/index.css";
@import "cropperjs/dist/cropper.css";
@import "boundless-modal/build/style.css";

.link:visited {
  color: inherit;
}

.shrink-0 {
  flex-shrink: 0;
}

button {
  border: none;
}

dl {
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
}
dd {
  -webkit-margin-start: 0;
}

.flex-align-right {
  margin-left: auto;
}

.align-items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.text-center {
  text-align: center;
}

.hidden {
  display: none !important;
}

.no-select {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.link {
  text-decoration: underline;
  cursor: pointer;
}