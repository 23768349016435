.side-menu {
  position: relative;
  left: 25px;
  padding: 10px 20px;
  width: 200px;
  height: 300px;
  background-color: #ECECEC;
  float: right;
  box-shadow: -2px 2px 3px 0px rgba(0,0,0,0.2);
  z-index: 10;
}

.processing-container {
  clear: both;
  margin-top: 25px;
  font-size: 0.9em;
}

.processing-select {
  margin-top: 10px;
}

.save-button {
  width: 100%;
  margin-top: 10px;
}

.road-offset {
  margin-top: 10px;
}

.road-offset-input {
  margin-top: 6px;
}