.area-line-container {
  position: absolute;
  bottom: 0;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 100px;
  overflow-x: auto;
  width: 100%;
}

.datapoints {
  display: flex;
}

.dp-container {
  width: 134px;
}

.dp-marker {
  position: relative;
  top: -6px;
  width: 16px;
  height: 16px;
  border-radius: 16px;
  border: 3px solid black;
  background-color: #f1f1f1;
  z-index: 5;
  float: left;
  cursor: pointer;
}

.dp-marker.interpreted {
  background-color: #40ff83;
}

.dp-line {
  width: 150px;
  border-top: 4px solid #ccc;
}

.dp-line.no-border {
  border: none;
}

.dp-label {
  width: 60px;
  text-align: center;
  margin-left: -22px;
  font-size: 12px;
  padding-bottom: 4px;
  cursor: pointer;
}

.dp-label.selected {
  color: #357edd;
  border-bottom: 4px solid #357edd;
  cursor: initial;
}

.spacer {
  width: 120px;
}

.zones-container {
  display: flex;
  position: absolute;
  bottom: 51px;
}

.zone {
  position: relative;
  left: 8px;
}

.zone-line {
  border-bottom: 8px solid;
}

.zone-line.not-selected {
  opacity: 0.3;
}

.zone-error {
  padding-top: 3px;
  border-bottom: 2px dotted red;
  cursor: pointer;
}

.zone-interpreted-1 {
  border-color: #363CFF;
  cursor: pointer;
}

.zone-interpreted-2 {
  border-color: #70B2FF;
  cursor: pointer;
}

.wide-1 {
  width: calc(1*134px);
}

.wide-2 {
  width: calc(2*134px);
}

.wide-3 {
  width: calc(3*134px);
}

.wide-4 {
  width: calc(4*134px);
}

.wide-5 {
  width: calc(5*134px);
}

.wide-6 {
  width: calc(6*134px);
}

.wide-7 {
  width: calc(7*134px);
}

.wide-8 {
  width: calc(8*134px);
}

.wide-9 {
  width: calc(9*134px);
}

.wide-10 {
  width: calc(10*134px);
}

.wide-11 {
  width: calc(11*134px);
}

.wide-12 {
  width: calc(12*134px);
}

.wide-13 {
  width: calc(13*134px);
}

.wide-14 {
  width: calc(14*134px);
}

.wide-15 {
  width: calc(15*134px);
}

.wide-16 {
  width: calc(16*134px);
}

.wide-17 {
  width: calc(17*134px);
}

.wide-18 {
  width: calc(18*134px);
}

.wide-19 {
  width: calc(19*134px);
}

.wide-20 {
  width: calc(20*134px);
}

.sign-container {
  height: 200px;
  position: relative;
  left: -51px;
  display: flex;
  flex-direction: column-reverse;
}

.sign {
  width: 114px;
  display: flex;
  height: 40px;
  align-items: center;
  margin-bottom: 20px;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 2px;
  background-color: white;
}

.sign-left-arrow, .sign-right-arrow {
  width: 15px;
}

.sign-category {
  width: 74px;
  font-size: 10px;
  text-align: center;
}

.sign-additional {
  width: 114px;
  text-align: center;
  font-size: 10px;
  margin-bottom: 10px;
}