.top-nav-container {
  background-color: #494949;
  height: 70px;
  display: flex;
  align-items: center;
}

.logo {
  width: 70px;
  height: 70px;
}

.title {
  padding: 0 32px;
  color: white;
  font-weight: normal;
}

.nav-link {
  display: block;
  height: 100%;
  padding: 0 32px;
  color: white;
  text-decoration: none;
  line-height: 70px;
}

.active {
  background-color: #888;
  border-bottom: 4px solid white;
}

.login-container {
  text-align: right;
  margin-left: auto;
}