.image-container {
  transition: transform 0.1s;
}

.editor-container {
  text-align: center;
}

.cropper-widget-container {
  height: 600px;
  overflow: hidden;
}