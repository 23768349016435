.schedule-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: rgba(0,0,0,0.7);
}

.schedule-component {
  background-color: white;
  margin: 0 auto;
  width: 100%;
  overflow-x: auto;
}

.schedule-container {
  width: 100%;
  overflow-x: auto;
}

.header {
  font-size: 1.5em;
  margin-top: 20px;
  padding: 0 20px;
  text-align: center;
  color: #666;
  font-weight: bolder;
}

.week-label-container, .week-grid-container {
  display: flex;
  width: 1000px;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.week-label-container {
  display: flex;
  width: 1000px;
  margin-bottom: 10px;
}

.week-grid-container {
  max-height: 300px;
  overflow-y: auto;
}

.day-column, .day-label {
  flex: none;
  width: 120px;
  margin: 0 10px;
  text-align: center;
}

.day-label {
  font-size: small;
  font-weight: bold;
  color: #999;
  text-align: center;
}

.day-schedule {
  /*font-size: 0.8em;*/
}

.schedule-block-container {
  height: 100px;
  margin-bottom: 12px;
  padding: 10px;
  border: 1px solid;
}

.block-time {
  text-align: right;
  padding-right: 20px;
  font-size: 0.9em;
}

.block-parking {
  margin-top: 6px;
  font-size: 0.8em;
}

.block-conditions {
  font-size: 0.8em;
}

.restricted_parking {
  background-color: rgba(192, 64, 228, 0.17);
  border: 1px solid rgb(192, 64, 228);
  color: #666;
}

.no_parking {
  background-color: rgba(224, 62, 54, 0.36);
  border: 1px solid rgb(224, 62, 54);
  color: #666;
}

.metered_parking {
  border: 1px solid rgb(40, 224, 142);
  background-color: rgba(40, 224, 142, 0.17);
  color: #666;
}

.unrestricted {
  border: 1px solid rgb(40, 224, 142);
  background-color: rgba(40, 224, 142, 0.17);
  color: #666;
}

.close {
  float: right;
  position: relative;
  top: -25px;
  right: 10px;
  color: #666;
  cursor: pointer;
}